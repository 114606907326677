// Composables
import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "home" */ '@/views/Home.vue'),
      },
    ],
  },
  {
    path: '/login',
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Login.vue'),
      },
    ],
  },
  {
    path: '/logout',
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'Logout',
        // Omit the component option
        beforeEnter: (to, from, next) => {
          // Run custom JavaScript logic here
          console.log("SHOPZON",'Logging out...');
          // For example, clear session storage and redirect
          localStorage.clear();
          document.cookie.split(';').forEach(cookie => {
              const eqPos = cookie.indexOf('=');
              const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
              document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
          });
    
          next('/login'); // Redirect to the login page
        },
      },
    ],
  },
  {
    path: '/register',
    component: () => import('@/layouts/default/DefaultLayout.vue'),
    children: [
      {
        path: '',
        name: 'Register',
        component: () => import(/* webpackChunkName: "home" */ '@/views/Register.vue'),
      },
    ],
  },
  {
    path: '/dashboard',
    component: () => import('@/layouts/default/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'Dashboard',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/Home.vue'),
      }
    ],
  },
  {
    path: '/dashboard/projects',
    component: () => import('@/layouts/default/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'Projects',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/Projects.vue'),
      },
      {
        path: 'add',
        name: 'AddProject',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/AddProject.vue'),
      },
      {
        path: 'view/:id',
        name: 'Project',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/Project.vue'),
      },
      {
        path: 'view/:id/scoring',
        name: 'ProjectScoring',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/ProjectScoring.vue'),
      },
      {
        path: 'view/:id/chatgpt',
        name: 'ProjectChatGPT',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/ProjectChatGPT.vue'),
      },
      {
        path: 'view/:id/setting',
        name: 'ProjectSetting',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/ProjectSetting.vue'),
      },
      {
        path: 'view/:id/instructions',
        name: 'ProjectInstructions',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/ProjectInstructions.vue'),
      }
    ],
  },
  {
    path: '/dashboard/profile',
    component: () => import('@/layouts/default/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'UserProfile',
        component: () => import(/* webpackChunkName: "home" */ '@/views/user/UserProfile.vue'),
      },
      {
        path: 'default-gpt-token',
        name: 'UserChatGPT',
        component: () => import(/* webpackChunkName: "home" */ '@/views/user/UserChatGPT.vue'),
      },
      {
        path: 'default-instruction',
        name: 'UserInstructions',
        component: () => import(/* webpackChunkName: "home" */ '@/views/user/UserInstructions.vue'),
      },
      {
        path: 'default-score',
        name: 'UserScores',
        component: () => import(/* webpackChunkName: "home" */ '@/views/user/UserScores.vue'),
      },
    ],
  },
  {
    path: '/dashboard/bulk-import',
    component: () => import('@/layouts/default/DashboardLayout.vue'),
    children: [
      {
        path: '',
        name: 'BulkImport',
        component: () => import(/* webpackChunkName: "home" */ '@/views/dashboard/BulkImport.vue'),
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
